body {
    background-color: #fac37b ;
}

.home {
    text-align:center;
}

.link {
    color:blue;
    text-decoration:underline;
}

.titulo {
    margin-bottom:15px;
    padding-bottom:15px;
    border-radius:15px;
    color:white;
    padding-top:5px;
    margin-top:10px;
    background-color:#e18000;
    width:30%;
    margin-left:35%;
    position: relative;
    text-align: center;
}

.titulo img {
    position: absolute;
    right:-15%;
    top:-30px;
    width:40%;
    transform: rotate(-30deg);
}




.opcoes-orcamento {
    background-color:#e18000;
    float:left;
    min-height:50px;
    width:70%;
    margin-left:15%;
    color:black;
    border-radius:20px;
    margin-top:25px;
    margin-bottom:25px;
    padding-top:20px;
    padding-bottom:20px;
}

.opcoes-orcamento img{
    width:35px;
}

.ou-entao {
    float:left;
    text-align:center;
    width:100%;
}


@media only screen and (max-width: 600px) {
    .opcoes-orcamento h3 {
        font-size:19px;
        font-weight:bold;
    }

    .titulo {
        margin-bottom:15px;
        padding-bottom:15px;
        border-radius:15px;
        color:white;
        padding-top:5px;
        margin-top:35px;
        background-color:#ff441a;
        width:60%;
        margin-left:20%;
        position: relative;
    }
    
    .titulo img {
        position: absolute;
        right:-15%;
        top:-30px;
        width:40%;
        transform: rotate(30deg);
    }
}
