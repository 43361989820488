img {
    width:70%;
}

.item-modal {
    background-color:white;
}


.mais-info {
    margin-left:20px;
}

.add-to-cart {
cursor: pointer;
}

.thumbnail img {
    width:100%;
    height:200px !important;
}