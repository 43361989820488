body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: red;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

img {
    width:70%;
}

.item-modal {
    background-color:white;
}


.mais-info {
    margin-left:20px;
}

.add-to-cart {
cursor: pointer;
}

.thumbnail img {
    width:100%;
    height:200px !important;
}
.product-info{
    color:blue;
    position:fixed;
    width: 60%;
    height: 70%;
    left:20%;
    top:20%;
    z-index: 5;
    background-color: #CCD080!important;
    padding:25px;
    border-radius:20px;
    overflow:auto;
}




.botao-fechar {
    position: absolute;
    background-color: red;
    color:white;
    text-align:center;
    font-size:30px;
    width:40px;
    height:40px;
    right:0;
    top:0;
    cursor: pointer;
}

.product-info-menu {
    margin-bottom:40px;
}

.product-info-menu ul {
    margin:0;
    padding:0;
    display: inline;
}

.product-info-menu ul li {
    list-style: none;
    margin-left:0;
    padding-left:0;
    display:inline;
    margin-right:15px;
    border-right:1px solid black;
}

.selecionar-tipo {
    color:white;
    font-size:25px;
    font-weight:bold;
    display:inline;
    margin-right:25px;
}

.productData img {
    width:100%;
}

.image-product {
    float:left;
    width: 35%;
    margin-right:40px;
}

.title {
    float:left;
    width:45%;
}

.title h2 {
    color:white;
}

span {
    font-weight: bold;
    display: block;
}

.title p,
.price p,
.kg {
    font-weight:bold;
    width:80px;
}

.add-to-cart {
        background-color: orange;
    padding:15px;
    border-radius:7px;
    color:white;
    font-weight: bold;
    margin-top:25px;
    float:left;
    text-align:center;
}


.observacao-kg {
    font-weight: normal;
    font-size:12px;
}

@media only screen and (max-width: 600px) {
    
    .product-info{
        color:blue;
        position:fixed;
        width: 80%;
        height: auto;
        left:10%;
        top:5%;
        z-index: 5;
        background-color: #CCD080!important;
        padding:25px;
        border-radius:20px;
        overflow:auto;
    }

    .productData img {
        width:auto;
        height:100px;
    }

    .image-product {
        text-align:center;
        float:none;
        display:block;
        width: 100%;
    }

    .title {
        float: none;
        width: 100%;
    }

    .product-info-menu button{
        margin-top:15px;
    }
    
}
.ordenar {
    text-align: center;
    margin-top: 25px;
    background-color:#e18000;

    width:30%;
    margin-left:35%;
    margin-bottom:20px;
}


.orderna-titulo {
    margin-bottom:15px;
}
.carrinho {
    padding-top:25px;
    padding-bottom:25px;
}

.carrinho h4, .carrinho h3 {
    text-align:center;
}


.itens-carrinho {
    width:50%;
    margin-left:25%;
    background-color: white;
    border-radius:15px;
    padding:20px;
}

.info-carrinho {
    text-align:center;
    font-weight: bold;
}

.valor-total {
    text-align:center;
}

.valor-total h4{
    margin-top:25px;
    font-weight: bold;
}

.botao-finalizar {
    background-color: orange;
    padding:15px;
    border-radius:7px;
    color:white;
    font-weight: bold;
    margin-top:25px;
    display:block;
    text-align:center;

}

.observacao-kg {
    margin-top:35px;
}

.itens-carrinho ul li {
    border-bottom:1px solid black;
    padding-bottom:15px;
}

.entrega {
    text-align:center;
}

@media only screen and (max-width: 600px) {
    .itens-carrinho {
        width:80%;
        margin-left: 10%;
    }
}
.basket-mobile {
    display: none;
}

@media only screen and (max-width: 600px) {
    .basket-mobile {
        display: inline;
        position: fixed;
        bottom:0;
        left:0;
        z-index:5;
        width:100%;
        margin-left:0%;
    }

    .itens-carrinho-mobile {
        width:100%;
        margin-left:0;
        background-color: white;
        height:80px;
    }

    .botao-finalizar-mobile {
        background-color: orange;
        padding:15px;
        border-radius:7px;
        color:white;
        font-weight: bold;
        text-align:center;
        position: absolute;
        right:20px;
        top:10px;
        height:50px;
    }

    .valor-total-mobile {
        line-height:50px;
        display:inline;
        position: absolute;
        left:20px;
        top:10px;
    }
    .valor-total-mobile h4{
        font-weight: bold;

    }
}


.lista-produtos h1 {
   margin-bottom:30px;
    color: white !important;
    font-weight: bold;
    text-align:center;
}

body {
    background-color: white;
}

* {
    color:black;
}

.filter {
    margin-bottom:15px;
}


.titulo {
    margin-bottom:15px;
    padding-bottom:15px;
    border-radius:15px;
    color:white;
    padding-top:5px;
    margin-top:10px;
    background-color:#ff441a;
    width:30%;
    margin-left:35%;
    position: relative;
}

.titulo img {
    position: absolute;
    right:-15%;
    top:-30px;
    width:40%;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
}




@media only screen and (max-width: 600px) {

    .titulo {
        margin-bottom:15px;
        padding-bottom:15px;
        border-radius:15px;
        color:white;
        padding-top:5px;
        margin-top:35px;
        background-color:#ff441a;
        width:60%;
        margin-left:20%;
        position: relative;
    }
    
    .titulo img {
        position: absolute;
        right:-15%;
        top:-30px;
        width:40%;
        -webkit-transform: rotate(30deg);
                transform: rotate(30deg);
    }
}

body {
    background-color: #fac37b ;
}

.home {
    text-align:center;
}

.link {
    color:blue;
    text-decoration:underline;
}

.titulo {
    margin-bottom:15px;
    padding-bottom:15px;
    border-radius:15px;
    color:white;
    padding-top:5px;
    margin-top:10px;
    background-color:#e18000;
    width:30%;
    margin-left:35%;
    position: relative;
    text-align: center;
}

.titulo img {
    position: absolute;
    right:-15%;
    top:-30px;
    width:40%;
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
}




.opcoes-orcamento {
    background-color:#e18000;
    float:left;
    min-height:50px;
    width:70%;
    margin-left:15%;
    color:black;
    border-radius:20px;
    margin-top:25px;
    margin-bottom:25px;
    padding-top:20px;
    padding-bottom:20px;
}

.opcoes-orcamento img{
    width:35px;
}

.ou-entao {
    float:left;
    text-align:center;
    width:100%;
}


@media only screen and (max-width: 600px) {
    .opcoes-orcamento h3 {
        font-size:19px;
        font-weight:bold;
    }

    .titulo {
        margin-bottom:15px;
        padding-bottom:15px;
        border-radius:15px;
        color:white;
        padding-top:5px;
        margin-top:35px;
        background-color:#ff441a;
        width:60%;
        margin-left:20%;
        position: relative;
    }
    
    .titulo img {
        position: absolute;
        right:-15%;
        top:-30px;
        width:40%;
        -webkit-transform: rotate(30deg);
                transform: rotate(30deg);
    }
}

button {
    color:black;
}

h1 {
    text-align: center;
}

.pagar-pagseguro {
    background-color: orange;
    padding:15px;
    border-radius:7px;
    color:black;
    font-weight: bold;
    float:left;
    text-align:center;
    width:20%;
    margin-left:40%;
}

.pagar-pagseguro img {
    width:20px;
    margin-left:15px;
}

.continuar-comprando {
    background-color: blue;
    padding:15px;
    border-radius:7px;
    color:white;
    font-weight: bold;
    margin-top:25px;
    float:left;
    text-align:center;
    width:20%;
    margin-left:40%;
}

.numero-contato {
    text-align: center;
    float:left;
    width:100%;
    font-weight:bold;
    margin-top:20px;
}

.ou-entao {
    text-align: center;
    float:left;
    width:100%;
    font-weight:bold;
    margin-top:10px;
    margin-bottom:10px;
}




@media only screen and (max-width: 600px) {
    .pagar-pagseguro, .continuar-comprando {
        width:80%;
        margin-left:10%;
    }
}
#shipping {
    background-color: white;
    text-align:center;
    float:left;
    width:60%;
    margin-left:20%;
    margin-top:50px;
    padding-left:5%;
    padding-right:5%;
}

.calcular-encomenda {
    background-color: orange;
    padding:15px;
    border-radius:7px;
    color:white;
    font-weight: bold;
    margin-top:25px;
    display:block;
    text-align:center;
    cursor: pointer;
}

.input-entrega {
    display:block;
    width:100%;
    margin-bottom:40px;
    text-align:center;
}

.input-encomenda {
    display:block;
    width:100%;
    margin-top:25px;
}

.preco-ceia, .preco-final {
    font-weight: bold;
}

.buscar-encomenda {
    color:blue;
    cursor: pointer;
}

.finalizar {
    margin-bottom:50px;
}

@media only screen and (max-width: 600px) {
#shipping {
    background-color: white;
    text-align:center;
    float:left;
    width:90%;
    margin-left:5%;
    margin-top:50px;
}

}
