.product-info{
    color:blue;
    position:fixed;
    width: 60%;
    height: 70%;
    left:20%;
    top:20%;
    z-index: 5;
    background-color: #CCD080!important;
    padding:25px;
    border-radius:20px;
    overflow:auto;
}




.botao-fechar {
    position: absolute;
    background-color: red;
    color:white;
    text-align:center;
    font-size:30px;
    width:40px;
    height:40px;
    right:0;
    top:0;
    cursor: pointer;
}

.product-info-menu {
    margin-bottom:40px;
}

.product-info-menu ul {
    margin:0;
    padding:0;
    display: inline;
}

.product-info-menu ul li {
    list-style: none;
    margin-left:0;
    padding-left:0;
    display:inline;
    margin-right:15px;
    border-right:1px solid black;
}

.selecionar-tipo {
    color:white;
    font-size:25px;
    font-weight:bold;
    display:inline;
    margin-right:25px;
}

.productData img {
    width:100%;
}

.image-product {
    float:left;
    width: 35%;
    margin-right:40px;
}

.title {
    float:left;
    width:45%;
}

.title h2 {
    color:white;
}

span {
    font-weight: bold;
    display: block;
}

.title p,
.price p,
.kg {
    font-weight:bold;
    width:80px;
}

.add-to-cart {
        background-color: orange;
    padding:15px;
    border-radius:7px;
    color:white;
    font-weight: bold;
    margin-top:25px;
    float:left;
    text-align:center;
}


.observacao-kg {
    font-weight: normal;
    font-size:12px;
}

@media only screen and (max-width: 600px) {
    
    .product-info{
        color:blue;
        position:fixed;
        width: 80%;
        height: auto;
        left:10%;
        top:5%;
        z-index: 5;
        background-color: #CCD080!important;
        padding:25px;
        border-radius:20px;
        overflow:auto;
    }

    .productData img {
        width:auto;
        height:100px;
    }

    .image-product {
        text-align:center;
        float:none;
        display:block;
        width: 100%;
    }

    .title {
        float: none;
        width: 100%;
    }

    .product-info-menu button{
        margin-top:15px;
    }
    
}