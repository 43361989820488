#shipping {
    background-color: white;
    text-align:center;
    float:left;
    width:60%;
    margin-left:20%;
    margin-top:50px;
    padding-left:5%;
    padding-right:5%;
}

.calcular-encomenda {
    background-color: orange;
    padding:15px;
    border-radius:7px;
    color:white;
    font-weight: bold;
    margin-top:25px;
    display:block;
    text-align:center;
    cursor: pointer;
}

.input-entrega {
    display:block;
    width:100%;
    margin-bottom:40px;
    text-align:center;
}

.input-encomenda {
    display:block;
    width:100%;
    margin-top:25px;
}

.preco-ceia, .preco-final {
    font-weight: bold;
}

.buscar-encomenda {
    color:blue;
    cursor: pointer;
}

.finalizar {
    margin-bottom:50px;
}

@media only screen and (max-width: 600px) {
#shipping {
    background-color: white;
    text-align:center;
    float:left;
    width:90%;
    margin-left:5%;
    margin-top:50px;
}

}