.carrinho {
    padding-top:25px;
    padding-bottom:25px;
}

.carrinho h4, .carrinho h3 {
    text-align:center;
}


.itens-carrinho {
    width:50%;
    margin-left:25%;
    background-color: white;
    border-radius:15px;
    padding:20px;
}

.info-carrinho {
    text-align:center;
    font-weight: bold;
}

.valor-total {
    text-align:center;
}

.valor-total h4{
    margin-top:25px;
    font-weight: bold;
}

.botao-finalizar {
    background-color: orange;
    padding:15px;
    border-radius:7px;
    color:white;
    font-weight: bold;
    margin-top:25px;
    display:block;
    text-align:center;

}

.observacao-kg {
    margin-top:35px;
}

.itens-carrinho ul li {
    border-bottom:1px solid black;
    padding-bottom:15px;
}

.entrega {
    text-align:center;
}

@media only screen and (max-width: 600px) {
    .itens-carrinho {
        width:80%;
        margin-left: 10%;
    }
}