.basket-mobile {
    display: none;
}

@media only screen and (max-width: 600px) {
    .basket-mobile {
        display: inline;
        position: fixed;
        bottom:0;
        left:0;
        z-index:5;
        width:100%;
        margin-left:0%;
    }

    .itens-carrinho-mobile {
        width:100%;
        margin-left:0;
        background-color: white;
        height:80px;
    }

    .botao-finalizar-mobile {
        background-color: orange;
        padding:15px;
        border-radius:7px;
        color:white;
        font-weight: bold;
        text-align:center;
        position: absolute;
        right:20px;
        top:10px;
        height:50px;
    }

    .valor-total-mobile {
        line-height:50px;
        display:inline;
        position: absolute;
        left:20px;
        top:10px;
    }
    .valor-total-mobile h4{
        font-weight: bold;

    }
}